import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Link } from "gatsby"


import lottie from "lottie-web"
import logoAnimation from "../data/kristina-logo-animated.json"



const LogoWrap = styled.div`
margin: auto 0;
min-width: 50px;
width: 100%;
align-self: center;
box-shadow: none;
flex: 2 1 80%;
height: 100%;
position: relative;

@media (max-width: 768px) {
  max-width: 100%;
  flex: 0 5 20%;
  top: -5px;
  left: -1rem;
}
@media (min-width: 769px) {
  padding-top: 0rem;

}
&:hover {

}
`
const LogoContainer = styled.div`

position: relative;
top: 0.5rem;
@media (max-width: 768px) {
  left: 0;
  height: inherit;
}
@media (min-width: 769px) {
height: 12vh;
 top: 10px;
}
`
const Logo = (props) => {
  let animationContainer = useRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: logoAnimation
    });
    anim.setSpeed(0.9)
    setTimeout(() => { anim.play() }, 750)
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <LogoWrap as={Link} to="/" onClick={props.click}>
      <LogoContainer ref={animationContainer} />

    </LogoWrap>
  )
}

export default Logo