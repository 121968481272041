import Typography from "typography"
// import Wordpress2016 from "typography-theme-wordpress-2016"

// Wordpress2016.overrideThemeStyles = () => {
//   return {
//     "a.gatsby-resp-image-link": {
//       boxShadow: `none`,
//     },
//     bodyFontFamily:["Lora"],
//   }
// }

//delete Wordpress2016.googleFonts

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.5,
  headerFontFamily: ['Lora', 'serif'],
  headerWeight: 400,
  bodyFontFamily: ['Lora', 'serif'],
  scaleRatio: 2.5,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm,}, options) => ({
    a: {
      color: '#af4319',
      textDecoration: 'none',
    }
  })
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
