import React from "react"
import { ThemeProvider } from "styled-components"

const theme = {
    colors: {
        primary: "#262261",
        primaryDark: "#1e1b4b",
        primaryLight: "#363088",
        primaryAlpha: "#26226188",
        secondary: "#a44200",
        secondaryDark: "#7a3100",
        secondaryLight: "#cc5200",
        background: "#FAF6F6",
        light: "#F8F2F2",
        black: "#140D00",
        black2: "#02111B",
        grey: "#8A8E91",

    },
}

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme