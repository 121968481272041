import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import { rhythm } from "../utils/typography"

const NavItem = styled(Link)`
  text-decoration: none;
  box-shadow: 0 1px 0 -0.5px black;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: ${props => props.theme.colors.primaryLight};
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: ${props => props.theme.colors.primaryLight};
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 600px) and (orientation: portrait) {
    padding-top: ${rhythm(1)};
    font-size: 6vw;
    z-index: 6;
  }
  @media (min-width: 601px) and (max-width: 768px) and (orientation: portrait) {
    padding-top: ${rhythm(1)};
    font-size: 36px;
    z-index: 6;
  }
`
const NavbarLinks = (props) => {
  const data = useStaticQuery(graphql`
  query Navbar {
    conductor: mdx(fileAbsolutePath: {regex: "/conductor.md/"}) {
      frontmatter {
        heading
      }
    }
    educator: mdx(fileAbsolutePath: {regex: "/panel-0.md/"}) {
      frontmatter {
        heading
      }
    }
    lyckokatter: mdx(fileAbsolutePath: {regex: "/lyckokatter.md/"}) {
      frontmatter {
        heading
      }
    }
    events: mdx(fileAbsolutePath: {regex: "/events.md/"}) {
      frontmatter {
        heading
      }
    }
    bio: mdx(fileAbsolutePath: {regex: "/bio.md/"}) {
      frontmatter {
        heading
      }
    }
    contact: mdx(fileAbsolutePath: {regex: "/contact.md/"}) {
      frontmatter {
        heading
      }
    }
  }
  `)
  return (
    <>
      <NavItem onClick={props.click} to="/">Home</NavItem>
      <NavItem onClick={props.click} to="/conductor">{data.conductor.frontmatter.heading}</NavItem>
      <NavItem onClick={props.click} to="/educator">{data.educator.frontmatter.heading}</NavItem>
      {/*<NavItem onClick={props.click} to="/lyckokatter">{data.lyckokatter.frontmatter.heading}</NavItem>*/}
      <NavItem onClick={props.click} to="/events">{data.events.frontmatter.heading}</NavItem>
      <NavItem onClick={props.click} to="/bio">{data.bio.frontmatter.heading}</NavItem>
      <NavItem onClick={props.click} to="/contact">{data.contact.frontmatter.heading}</NavItem>
    </>
  )
}

export default NavbarLinks