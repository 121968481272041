import React, { useState } from "react"

import Logo from "../components/logo"


import styled from "styled-components"
import NavbarLinks from "../components/navbar-links"

const Wrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin: 0 auto;
padding: 0 1.5rem;

background-color: ${props=> props.theme.colors.background};
height: 10vh;
position: sticky;
top: 0;
z-index: 999;
@media (min-width: 769px) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 15vh;
  padding-bottom: 1rem;
}
@media (max-width: 600px) and (orientation: landscape) {
  height: 15vh;
}
`

const Navigation = styled.nav`


background-color: transparent;
position: relative;
justify-content: center;
text-transform: uppercase;


z-index: 2;
align-self: center;
@media (max-width: 768px) {
  align-items: flex-end;
  justify-content: flex-end;
  position: sticky;
  height: 8vh;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
}

`
const Toggle = styled.div`
display: none;
  height: 100%;
  cursor: pointer;
  

  @media (max-width: 768px) {
    display: flex;
    flex: 0 3 auto;
    align-self: right;
  }
  `
const Navbox = styled.div`
display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    background-color: ${props=> props.theme.colors.background};
    transition: all 0.3s ease-in;
    top: 9.75vh;
    left: ${props => (props.open ? "-100%" : "0")};
    z-index: 9999999;
  }
  @media (max-width: 600px) and (orientation: landscape) {
    top: 14.75vh;
  }
  
`
const Burger = styled.div`
background-color: ${props => props.theme.colors.primary};
  width: 30px;
  height: 2px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
    props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
const Links = styled(NavbarLinks)`

`



const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (

    <Wrapper>

      <Logo click={() => setNavbarOpen(false)}/>

      <Navigation>

        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Burger open /> : <Burger />}
        </Toggle>
        {navbarOpen ? (
          <Navbox>
            <Links click={() => setNavbarOpen(!navbarOpen)} />
          </Navbox>
        ) : (<Navbox open>
          <Links />
        </Navbox>)}
      </Navigation>
    </Wrapper>

  )
}

export default Navbar