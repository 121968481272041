import React from "react"
import styled from "styled-components"
import { rhythm } from "../utils/typography"

import Social from "../components/social"

const Bottom = styled.div`
color: ${props => props.theme.colors.grey};
a {
    color: ${props => props.theme.colors.grey};
    transition: all 0.5s;
    &:hover {
        color: ${props => props.theme.colors.background}
    }
}
`

const Container = styled.div`
background-color: ${props => props.theme.colors.primary};
padding: ${rhythm(1)};
text-align: center;
`

const Footer = () => {
    return (
        <>
            <Container>
                <Bottom>
                    
            <Social/>
            <p>
                        © {new Date().getFullYear()} Kristina Bogataj
            </p>
            <p>
            Y-tunnus: 3144877-2
            </p>
                    {/*<p>Website developed by <a href="https://www.anttisuomalainen.com">Antti Suomalainen</a></p>*/}
                </Bottom>
                
            </Container>
        </>
    )
}

export default Footer