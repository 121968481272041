// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-choral-conducting-js": () => import("./../../../src/pages/choral-conducting.js" /* webpackChunkName: "component---src-pages-choral-conducting-js" */),
  "component---src-pages-conductor-js": () => import("./../../../src/pages/conductor.js" /* webpackChunkName: "component---src-pages-conductor-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-educator-js": () => import("./../../../src/pages/educator.js" /* webpackChunkName: "component---src-pages-educator-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-musical-beginnings-js": () => import("./../../../src/pages/musical-beginnings.js" /* webpackChunkName: "component---src-pages-musical-beginnings-js" */),
  "component---src-pages-piano-js": () => import("./../../../src/pages/piano.js" /* webpackChunkName: "component---src-pages-piano-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-pages-vocal-technique-js": () => import("./../../../src/pages/vocal-technique.js" /* webpackChunkName: "component---src-pages-vocal-technique-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */)
}

