import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faPinterest, faLinkedin, faYoutube, faTwitter, faSoundcloud } from "@fortawesome/free-brands-svg-icons"

const fb = <FontAwesomeIcon icon={faFacebook} />
const ig = <FontAwesomeIcon icon={faInstagram} />
const pint = <FontAwesomeIcon icon={faPinterest} />
const yt = <FontAwesomeIcon icon={faYoutube} />
const li = <FontAwesomeIcon icon={faLinkedin} />
const twitter = <FontAwesomeIcon icon={faTwitter}/>
const sc = <FontAwesomeIcon icon={faSoundcloud}/>



const Social = () => {
    const data = useStaticQuery(graphql`
    query Social {
        site {
            siteMetadata {
                social {
                    facebook
                    instagram
                    twitter
                    linkedin
                    youtube
                    pinterest
                    soundcloud
                }
            }
        }
    }
    `);

    const social = data.site.siteMetadata.social

    return (
        <Container>
            {
                social.facebook &&
                <Icon href={social.facebook} target="_blank" rel="noreferrer noopener">{fb}</Icon>
            }
            {social.instagram &&
            <Icon href={social.instagram} target="_blank" rel="noreferrer noopener">{ig}</Icon>
}
{social.twitter &&
            <Icon href={social.twitter} target="_blank" rel="noreferrer noopener">{twitter}</Icon>
}
{social.linkedin &&
            <Icon href={social.linkedin} target="_blank" rel="noreferrer noopener">{li}</Icon>
}
{social.youtube &&
            <Icon href={social.youtube} target="_blank" rel="noreferrer noopener">{yt}</Icon>
}
{social.pinterest &&
            <Icon href={social.pinterest} target="_blank" rel="noreferrer noopener">{pint}</Icon>
}
{social.soundcloud &&
            <Icon href={social.soundcloud} target="_blank" rel="noreferrer noopener">{sc}</Icon>
}
        </Container>
    )
}

export default Social

const Container = styled.div`
display: flex;
justify-content: center;
`
const Icon = styled.a`
font-size: 1.33 rem;
padding: 0.5rem;
`