import React from "react"
import styled from "styled-components"
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions"

import { rhythm } from "../utils/typography"
import Theme from "../utils/theme"
import Navbar from "../components/navbar"
import Footer from "../components/footer"


const Container = styled.div`
width: 100%;
background-color: ${props => props.theme.colors.background};
color: ${props => props.theme.colors.black};
*:focus {
  outline: -webkit-focus-ring-color ${props => props.theme.colors.primary} 1px!important;
}
`
const Body = styled.div`
margin: 0 auto;
margin-bottom: ${rhythm(3)};
max-width: ${rhythm(24)};
padding: 0 ${rhythm(3 / 4)};
min-height: 100vh;
`

const Layout = ({ location, children }) => {
  return (
    <TransitionProvider location={location}
      mode='successive'
      enter={{
        opacity: 0,
        config: {
          duration: 200
        },

      }}
      usual={{
        opacity: 1,

      }}
      leave={{
        opacity: 0,

        config: {
          duration: 200
        }
      }}>
      <Theme>

        <Container>
          <Navbar />
          <Body>

            <TransitionViews>

              {children}

            </TransitionViews>

          </Body>
          <Footer />
        </Container>

      </Theme>
    </TransitionProvider>

  )
}

export default Layout